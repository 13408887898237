<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="1000"
      style="overflow-y: hidden !important"
      class="dialogModal"
    >
      <template v-slot:activator="{ on }">
        <div class="circle1 pointer" v-on="on">
          <img
            class="hov_cir"
            v-bind:style="classHover"
            src="../../assets/hover_circle.png"
            alt="sombra"
          />
          <img
            class="cir"
            @mouseover="$emit('hovImage')"
            @mouseleave="$emit('leaveImage')"
            src="https://image-store.isocialcube.com/advise/1.webp"
            alt=""
          />
        </div>
      </template>

      <v-card flat>
        <!-- <vue-custom-scrollbar class="scroll-area" :settings="settings"> -->
        <v-container class="px-5">
          <div class="d-flex" style="max-height: 100%; height: 100%; width: 100%">
            <div style="width: 25%;" class="pa-0">
              <v-row justify="center">
                <img
                  src="https://image-store.isocialcube.com/advise/how-to/1.webp"
                  style="width: 100%;"
                />
              </v-row>
            </div>
            <div style="width: 72%; padding-bottom: 2rem;" class="card-content">
              <div class="container-card-content">
                <v-card-title class="card-title">
                  ¿Qué es ADVISE?
                </v-card-title>
                <v-card-text class="text-justify card-body-text">
                  <p>
                    Somos tu 'Asesor' de analítica para Redes Sociales On-Line.
                    Analizamos el entorno global para obtener diangósticos
                    específicos para ti. Nuestro Grupo de Expertos cuenta con
                    Robots de Inteligencia Artificial que facilita sus labores
                    en 3 ámbitos.
                  </p>
                  <ul class="my-4">
                    <li>Político - Económico</li>
                    <li>Mercado</li>
                    <li>Empresarial (PYME y Micro-Empresa)</li>
                  </ul>
                  <p>Nuestro análisis lo canalizamos a través de:</p>
                  <ul class="my-4">
                    <li>
                      <b>'Insights'</b> para trazar la Estrategia de tu Meta, y
                    </li>
                    <li>
                      <b>'Acciones'</b> a ejecutarse para el cumplimiento
                      operacional de tu Meta.
                    </li>
                  </ul>
                  <p>
                    'Insights' es el 'descubrimiento' de información útil,
                    dentro de un conjunto de dato, que no se hubiera podido
                    identificar a simple vista sin la utilización de tecnologia
                    y visión analítica. Se usa para optimizar decisiones con una
                    óptica de pensamiento 'Out of the Box'.
                  </p>
                  <br />
                  <iframe
                    width="100%"
                    height="300"
                    src="https://www.youtube.com/embed/LtqMdKyk0NA"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </v-card-text>
              </div>
            </div>
            <div style="width: 3%;">
              <div style="width: 100%;" class="d-flex justify-center">
                <button @click="dialog = false"><img :src="openCloseModalButton" alt="x"></button>
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import openCloseModalButton from "@/assets/openCloseModalButton.svg";
export default {
  name: "FirstModal",
  data() {
    return {
      dialog: false,
      openCloseModalButton,
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
    };
  },
  props: {
    classHover: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
  },
};
</script>

<style>
/* .v-dialog.v-dialog--active {
  padding-top: 1rem; 
  padding-right: 1rem;
  background-color: white;
} */

.v-dialog.v-dialog--active .v-card {
  overflow-y: hidden;
  box-shadow: none;
}
</style>

<style scoped>
::v-deep .v-dialog {
  overflow-y: hidden;
}
.card-content::-webkit-scrollbar {
  position: absolute;
  width: 3px;
  height: 0;
}
.card-content::-webkit-scrollbar-thumb:hover {
  background: hsla(0, 0%, 100%, 0.4);
}
.card-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0;
  opacity: 1;
}
ul ul {
  list-style-type: disc;
}
li::marker {
  font-size: 20px;
}
</style>
