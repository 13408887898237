<template>
  <div>
    <v-dialog v-model="dialog2" max-width="1000">
      <template v-slot:activator="{ on }">
        <div class="circle3 pointer" v-on="on">
          <img
            class="hov_cir"
            v-bind:style="classHover"
            src="../../assets/hover_circle.png"
            alt="sombra"
          />
          <img
            class="cir"
            @mouseover="$emit('hovImage')"
            @mouseleave="$emit('leaveImage')"
            src="https://image-store.isocialcube.com/advise/3.webp"
            alt=""
          />
        </div>
      </template>
      <v-card max-height="700" min-height="700">
        <v-container class="py-5" style="padding-right: 1rem !important; padding-left: 1rem !important">
          <div class="d-flex">
            <div style="width: 25%;">
              <v-row justify="center">
                <img
                  class="cir"
                  src="https://image-store.isocialcube.com/advise/how-to/3.webp"
                  style="width: 100%;"
                />
              </v-row>
            </div>
            <div style="width: 72%;" class="card-content px-8">
              <v-card-title class="card-title pt-0">
                ¿Qué metas puedo lograr con ADVISE?
              </v-card-title>
              <v-card-text class="text-justify card-body-text mb-10">
                <p>
                  Para usar ADVISE debes seleccionar una meta y un objetivo que
                  encontrarás en los submódulos 'GET HELP' y 'DO IT YOURSELF.
                  Puedes seleccionar un objetivo específico dentro de los 2
                  tipos de metas que se ofrecen.
                </p>
                <p class="card-body-title">
                  Meta 1: Campaña de Marketing en Redes
                </p>
                <ul class="mb-4">
                  <li>Lanzamiento de Perfil Social</li>
                  <li>Campaña para audiencia | Branding | Leads</li>
                  <li>Diseño de Piezas de Marketing</li>
                </ul>
                <p class="card-body-title">Meta 2: Análisis de Entornos</p>
                <ul class="mb-4">
                  <li>Análisis de Mercado</li>
                  <li>Análisis de Marca</li>
                  <li>Análisis de Producto | Servicio</li>
                </ul>
                <br />
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/LtqMdKyk0NA"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-card-text>
            </div>
            <div style="width: 3%;">
              <div style="width: 100%;" class="d-flex justify-center">
                <button @click="dialog2 = false"><img :src="openCloseModalButton" alt="x"></button>
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import openCloseModalButton from "@/assets/openCloseModalButton.svg";
export default {
  data() {
    return {
      openCloseModalButton,
      dialog2: false,
    };
  },
  name: "ThirdModal",
  props: {
    classHover: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    openModal() {
      this.dialog2 = true;
    },
  },
};
</script>

<style scoped>
::v-deep .v-dialog {
  overflow-y: hidden;
}
.card-content::-webkit-scrollbar {
  position: absolute;
  width: 3px;
  height: 0;
}
.card-content::-webkit-scrollbar-thumb:hover {
  background: hsla(0, 0%, 100%, 0.4);
}
.card-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0;
  opacity: 1;
}
ul ul {
  list-style-type: disc;
}
li::marker {
  font-size: 20px;
}
</style>
