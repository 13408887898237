<template>
  <div>
    <v-dialog v-model="dialog1" max-width="1000">
      <template v-slot:activator="{ on }">
        <div class="circle2 pointer" v-on="on">
          <img
            class="hov_cir"
            v-bind:style="classHover"
            src="../../assets/hover_circle.png"
            alt="sombra"
          />
          <img
            class="cir"
            @mouseover="$emit('hovImage')"
            @mouseleave="$emit('leaveImage')"
            src="https://image-store.isocialcube.com/advise/2.webp"
            alt=""
          />
        </div>
      </template>
      <v-card max-height="700" min-height="700">
        <!-- <v-container class="px-10 py-5"> -->
        <v-container
          class="pt-0"
          style="padding-right: 1rem !important; padding-left: 1rem !important"
        >
          <div class="d-flex">
            <div style="width: 20%">
              <v-row justify="center" class="p-0">
                <img
                  class="cir"
                  src="https://image-store.isocialcube.com/advise/how-to/2.webp"
                  style="width: 100%"
                />
              </v-row>
            </div>
            <div
              style="width: 77%; padding-bottom: 2rem"
              class="py-5 px-10 card-content"
            >
              <v-card-title class="card-title">
                ¿Cómo uso Advise?
              </v-card-title>
              <v-card-text class="text-justify card-body-text">
                <p>Puedes usar ADVISE de dos maneras:</p>
                <ul class="my-4">
                  <li>
                    Acceder al apoyo de un especialista que te brinde una guía
                    de acción para cumplir tu meta.
                  </li>
                  <li>
                    De manera independiente y desde la guía digital de la
                    plataforma de ISC, podrás ejecutar todas las acciones paso a
                    paso para lograr tu meta.
                  </li>
                </ul>
                <p>
                  <b>Para acceder al apoyo:</b>Ingresas a “Obtener Ayuda” (Get
                  Help) y sigue los siguientes pasos.
                </p>
                <ol class="my-4">
                  <li>
                    Completa todos los campos de la Ficha de Datos (Company
                    Brief).
                  </li>
                  <li>
                    Elige una meta y un objetivo a lograr (Goals Definition).
                  </li>
                  <li>Abre un Ticket de Servicio (Get a Ticket).</li>
                </ol>
                <p>Posterior a ello, recibirás un mail de confirmación.</p>
                <p>A partir de tu solicitud se realizará lo siguiente:</p>
                <ul class="my-4">
                  <li>
                    Nuestros expertos usarán robots de Inteligencia Artificial
                    para escuchar la información en Redes Sociales del sector en
                    el que se encuentra cada empresa y, en base a ello, se
                    realiza un diagnóstico de industria (Módulos LISTEN y
                    MONITOR).
                  </li>
                  <li>
                    En base a la escucha y el descubrimiento de 'Insights' se
                    traza una estrategia.
                  </li>
                  <li>
                    Se ejecutan las acciones basadas en la estrategia y,
                    finalmente, se realiza un diagnóstico (Módulos MONITOR y
                    DESIGN).
                  </li>
                </ul>
                <p>
                  <b
                    >Para realizar las escuchas, la implementación y la
                    retroalimentación de manera independiente,</b
                  >
                  te llevaremos de la mano para que aprendas a hacerlo solo.
                  Para ello, tendrás una guía con videos y metodología en
                  “Hágalo usted mismo” en (Do It Yourself).
                </p>
                <br />
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/LtqMdKyk0NA"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-card-text>
            </div>
            <div style="width: 3%">
              <div style="width: 100%" class="d-flex justify-center">
                <button @click="dialog1 = false">
                  <img :src="openCloseModalButton" alt="x"/>
                </button>
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import openCloseModalButton from "@/assets/openCloseModalButton.svg";
export default {
  data() {
    return {
      openCloseModalButton,
      dialog1: false,
    };
  },
  name: "SecondModal",
  props: {
    classHover: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    openModal() {
      this.dialog1 = true;
    },
  },
};
</script>
<style scoped>
ul ul {
  list-style-type: disc;
}
ul li::marker {
  font-size: 20px;
}
</style>

<style scoped>
::v-deep .v-dialog {
  overflow-y: hidden;
}
.card-content::-webkit-scrollbar {
  position: absolute;
  width: 3px;
  height: 0;
}
.card-content::-webkit-scrollbar-thumb:hover {
  background: hsla(0, 0%, 100%, 0.4);
}
.card-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0;
  opacity: 1;
}
ol li {
  padding-left: 12px;
}
ul li {
  padding-left: 4px;
}
ul li::marker {
  font-size: 18px;
}
ol {
  padding-left: 12px;
}
ul {
  padding-left: 16px;
}
</style>
