import Vue from "vue";
import VueRouter from "vue-router";
import HowTo from "../views/HowTo";

Vue.use(VueRouter);

const routes = [
  {
    path: "/how-to",
    name: "how-to",
    component: HowTo,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
