<template>
  <div style="height: 100%;">
    <v-container class="full" fluid >
      <v-row class="izq" justify="center">
        <h1>Quick Analytics Guideline</h1>
      </v-row>
      <v-row class="der" justify="center">
        <intro />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Intro from "../components/Intro";

export default {
  name: "howto",
  components: {
    Intro,
  },
  data: () => ({
    isScreensizeLess: false,
  }),
  methods: {
  },
  created() {   
  },
};
</script>

<style lang="scss" scoped>
.full {
  color: #2c3852;
  background-color: #f3f4f7;
}

.izq {
  // background-color: orange;
  h1 {
    padding: 20px 0 20px 0;
    font-size: 2.25em;
    line-height: 1.1;
    top: 10%;
  }
}
</style>
