<template>
  <div>
    <v-dialog v-model="dialog3" max-width="1000">
      <template v-slot:activator="{ on }">
        <div class="circle4 pointer" v-on="on">
          <img
            class="hov_cir"
            v-bind:style="classHover"
            src="../../assets/hover_circle.png"
            alt="sombra"
          />
          <img
            class="cir2"
            @mouseover="$emit('hovImage')"
            @mouseleave="$emit('leaveImage')"
            src="https://image-store.isocialcube.com/advise/4.webp"
            alt=""
          />
        </div>
      </template>
      <v-card max-height="700" min-height="700">
        <v-container class="py-5" style="padding-right: 1rem !important; padding-left: 1rem !important">
          <div class="d-flex">
            <div style="width: 25%;">
              <v-row justify="center">
                <img
                  class="cir"
                  src="https://image-store.isocialcube.com/advise/how-to/4.webp"
                  style="width: 100%;"
                />
              </v-row>
            </div>
            <div style="width: 72%;" class="card-content px-5">
              <v-card-title class="card-title pt-0">
                ¿A qué le debo prestar atención?
              </v-card-title>
              <v-card-text class="text-justify card-body-text mb-10">
                <ul class="pl-3">
                  <li>
                    Te recomendamos seleccionar estratégicamente los Drivers (palabras, conjunto de palabras, hashtags, menciones en Twitter, Facebook pages) para generar una escucha correcta en LISTEN y recolectar información valiosa o <b>INSIGHTS</b> que te ayuden a tomar decisiones idóneas. El mercado no se comporta igual  en todas las Redes Sociales, por ello no todos los drivers son necesariamente los adecuados.
                  </li>
                  <li class="pt-5">
                    Los <b>'Contextos'</b> (Data de cada Dashboard en LISTEN) se construyen en el tiempo. Los datos no se mostrarán inmediatamente.
                  </li>
                  <li class="pt-5">
                    Te sugerimos monitorear tus Social Profiles (Perfiles) y realizar un Benchmark o monitoreo de tus competidores, de esta manera podrás conseguir información relevante que complemente tu análisis: ¿En qué aspecto tengo un buen desempeño? ¿En qué aspectos no? ¿Cuál es el feedback de mi audiencia? ¿Cuáles son las campañas de marketing de mi competencia que son similares a la mía? ¿Cuáles son las novedades que existen en el mercado?, entre otros. De esta manera, tomarás el camino correcto en la formulación de tu estrategia.
                  </li>
                  <li class="py-5">
                    <b>IsocialCube (ISC)</b> te brinda un mundo de información. Aprenderás a sacarle el máximo provecho, con la práctica obtendrás excelentes resultados.
                  </li>
                  <li>
                    Progresivamente descubrirás cómo explotar ISC al adaptar e incluir la transformación digital a tu mundo.
                  </li>
                  <br />
                  <iframe
                    width="100%"
                    height="300"
                    src="https://www.youtube.com/embed/LtqMdKyk0NA"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </ul>
              </v-card-text>
            </div>
            <div style="width: 3%;">
              <div style="width: 100%;" class="d-flex justify-center">
                <button @click="dialog3 = false"><img :src="openCloseModalButton" alt="x"></button>
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import openCloseModalButton from "@/assets/openCloseModalButton.svg";
export default {
  data() {
    return {
      dialog3: false,
      openCloseModalButton,
    };
  },
  name: "FourthModal",
  props: {
    classHover: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    openModal() {
      this.dialog3 = true;
    },
  },
};
</script>
<style scoped>
li {
  list-style-position: inside;
}
</style>
<style scoped>
::v-deep .v-dialog {
  overflow-y: hidden;
}
.card-content::-webkit-scrollbar {
  position: absolute;
  width: 3px;
  height: 0;
}
.card-content::-webkit-scrollbar-thumb:hover {
  background: hsla(0, 0%, 100%, 0.4);
}
.card-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0;
  opacity: 1;
}
ul ul {
  list-style-type: disc;
}
li::marker {
  font-size: 20px;
}
</style>
