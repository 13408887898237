<template>
  <div class="infogram">
    <first-modal
      ref="modal1"
      :classHover="classHover1"
      @hovImage="hover(1)"
      @leaveImage="leave(1)"
    />
    <second-modal
      ref="modal2"
      :classHover="classHover2"
      @hovImage="hover(2)"
      @leaveImage="leave(2)"
    />
    <third-modal
      ref="modal3"
      :classHover="classHover3"
      @hovImage="hover(3)"
      @leaveImage="leave(3)"
    />
    <fourth-modal
      ref="modal4"
      :classHover="classHover4"
      @hovImage="hover(4)"
      @leaveImage="leave(4)"
    />
    <div class="infodot1">
      <div
        class="dot"
        @mouseover="hover(1)"
        @mouseleave="leave(1)"
        v-bind:style="dotHover1"
        @click="openModal(1)"
      >
        <div class="tooltip tt1">
          <div class="tt-title" v-bind:style="titleHover1">¿Qué es ADVISE?</div>
          <div class="tt-text">Somos tu asesor de analítica online.</div>
        </div>
      </div>
    </div>
    <div class="infodot2">
      <div
        class="dot"
        @mouseover="hover(2)"
        @mouseleave="leave(2)"
        v-bind:style="dotHover2"
        @click="openModal(2)"
      >
        <div class="tooltip tt2">
          <div class="tt-title" v-bind:style="titleHover2">
            ¿Cómo uso ADVISE?
          </div>
          <div class="tt-text">Pide ayuda y nosotros te guiaremos.</div>
        </div>
      </div>
    </div>
    <div class="infodot3">
      <div
        class="dot"
        @mouseover="hover(3)"
        @mouseleave="leave(3)"
        v-bind:style="dotHover3"
        @click="openModal(3)"
      >
        <div class="tooltip tt1">
          <div class="tt-title" v-bind:style="titleHover3">
            ¿Qué metas puedo lograr con ADVISE?
          </div>
          <div class="tt-text">
            Elige una meta entre los 3 tipos que tenemos y un objetivo por cada
            meta.
          </div>
        </div>
      </div>
    </div>
    <div class="infodot4">
      <div
        class="dot"
        @mouseover="hover(4)"
        @mouseleave="leave(4)"
        v-bind:style="dotHover4"
        @click="openModal(4)"
      >
        <div class="tooltip tt2">
          <div class="tt-title" v-bind:style="titleHover4">
            ¿A qué le debo prestar atención?
          </div>
          <div class="tt-text">
            Elegir bien tus drivers, monitorear tus redes, etc<br />¡Tenemos un
            mundo entero de información!
          </div>
        </div>
      </div>
    </div>
    <img
      id="whitecircles"
      src="https://image-store.isocialcube.com/advise/whitecircles.webp"
      alt=""
    />
  </div>
</template>

<script>
import FirstModal from "./modals/FirstModal";
import SecondModal from "./modals/SecondModal";
import ThirdModal from "./modals/ThirdModal";
import FourthModal from "./modals/FourthModal";

export default {
  name: "Intro",
  components: {
    FirstModal,
    SecondModal,
    ThirdModal,
    FourthModal,
  },
  data() {
    return {
      classHover1: {
        display: "none",
      },
      dotHover1: {},
      titleHover1: {},
      classHover2: {
        display: "none",
      },
      dotHover2: {},
      titleHover2: {},
      classHover3: {
        display: "none",
      },
      dotHover3: {},
      titleHover3: {},
      classHover4: {
        display: "none",
      },
      dotHover4: {},
      titleHover4: {},
    };
  },
  methods: {
    hover(numModal) {
      this["dotHover" + numModal] = {
        background: "#00a5ff",
      };
      this["titleHover" + numModal] = {
        color: "#00a5ff",
      };
      this["classHover" + numModal] = {
        display: "block",
      };
    },
    leave(numModal) {
      this["dotHover" + numModal] = {};
      this["titleHover" + numModal] = {};
      this["classHover" + numModal] = {
        display: "none",
      };
    },
    openModal(numModal) {
      this.$refs["modal" + numModal].openModal();
    },
  },
};
</script>
<style scoped>
.infodot2 .tt-text {
  width: 130px;
}
.infodot3 .tt-title {
  width: 275px;
}
.infodot3 .tt-text {
  width: 210px;
}
.infodot4 .tt-title {
  width: 235px;
}
.infodot4 .tt-text {
  width: 275px;
}

b {
  color: #2c3852;
  font-weight: 600;
}
</style>
<style lang="scss">
.scroll-area {
  position: relative;
  margin: auto;
  width: 1300px;
  height: 750px;
}

.card-content {
  padding-top: 20px;
}

.card-title {
  color: #2c3852;
  font-weight: 700 !important;
  font-size: 24px !important;
}
.card-content {
  max-height: 600px;
  overflow-y: auto;
}

.card-body-title {
  margin: 0;
  font-weight: 600;
  color: #2c3852;
}

.card-body-text {
  font-size: 12px !important;
  color: #5d6b88;
  font-family: "Oxygen";
  font-style: normal;
  line-height: 18px !important;
}

.infogram {
  margin-left: 2%;
  margin-top: 10%;
  margin-bottom: 10%;

  #whitecircles {
    width: 773px;
    height: 211px;
    z-index: 1;
  }

  .cir {
    width: 125px;
    height: 125px;
  }

  .hov_cir {
    position: absolute;
    width: 125px;
    height: 125px;
    z-index: -1;
  }

  .cir2 {
    width: 125px;
    height: 125px;
  }

  .circle1 {
    z-index: 99;
    position: absolute;
    margin: 43px 0px 0px 27px;
  }

  .pointer {
    cursor: pointer;
  }
  .circle2 {
    z-index: 99;
    position: absolute;
    margin: 43px 0 0 225px;
  }

  .circle3 {
    z-index: 99;
    position: absolute;
    margin: 43px 0 0 423px;
  }

  .circle4 {
    z-index: 3;
    position: absolute;
    margin: 43px 0 0 621px;
  }

  .infodot1 {
    position: absolute;
    padding: 6px 0px 0px 85px;
  }

  .infodot2 {
    position: absolute;
    padding: 196px 0px 0px 281px;
  }

  .infodot3 {
    position: absolute;
    padding: 6px 0px 0px 477px;
  }

  .infodot4 {
    position: absolute;
    padding: 196px 0px 0px 673px;
  }

  .dot {
    width: 12px;
    height: 12px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    background: #5d6b88;
    position: relative;
    z-index: 2;

    .tt1 {
      position: absolute;
      z-index: 2;
      bottom: 20px;
      left: -5rem;
      margin-left: -60px;
    }

    .tt2 {
      position: absolute;
      z-index: 2;
      top: 20px;
      left: -5rem;
      margin-left: -60px;
    }

    .tooltip {
      font-family: "Oxygen", sans-serif;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      // width: 150px;
      text-align: left;
      border-radius: 6px;
      padding: 5px 0;
      opacity: 1;
      transition: opacity 0.3s;

      width: 19rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .tt-title {
        font-size: 15px;
        font-weight: 700;
        line-height: 16px;
        color: #2c3852;
        cursor: pointer;
      }

      .tt-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #5d6b88;
        text-align: center;
      }
    }

    &:hover {
      background: #00a5ff;
      cursor: pointer;
      .tooltip {
        cursor: context-menu;
      }

      .tt-title {
        color: #00a5ff;
      }
    }
  }
}
</style>
